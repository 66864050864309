'use client';
import { PostHogTracking } from '@/app/_lib/posthog';
import { PostHogProvider } from 'posthog-js/react';
import React from 'react';

export function CSPostHogProvider({ children }: { children: React.ReactNode }) {
  return (
    <PostHogProvider client={PostHogTracking.getInstance()?.postHogClient}>
      {children}
    </PostHogProvider>
  );
}
